import Info from "@/store/info";
import { IInfoState } from "@/models/Store";
import { IUserDevice } from "@/models/Device";

/* MOCKS */
jest.mock("@/api");

beforeEach(() => {
  jest.restoreAllMocks();
});

const state: IInfoState = Info.state;

describe("Info", () => {
  describe("mutations", () => {
    it("SET_DEVICE", () => {
      // Given
      const device: IUserDevice = { device: "ipad" };
      // When
      Info.mutations.SET_DEVICE(state, device);
      // Then
      expect(state.userDevice).toBe(device);
    });
  });

  describe("getters", () => {
    it("getDevice", () => {
      // Given
      // When
      const result = Info.getters.getDevice(state);
      // Then
      expect(result).toBe(state.userDevice);
    });
  });
});
